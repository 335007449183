<template>
  <el-form :label-position="labelPosition" :model="Student" v-loading="loading" label-width="120px"
    style="max-width: 460px">
    <el-form-item label="輔大LDAP帳號" required>
      <el-input placeholder="LDAP ID" v-model="Student.mail_id"></el-input>
    </el-form-item>
    <el-form-item label="輔大LDAP密碼" required>
      <el-input show-password placeholder="LDAP PWD" v-model="Student.mail_pwd"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="clickSubmit()">驗證帳號</el-button>
    </el-form-item>
    <el-alert v-if="showSucess" title="LDAP登入成功" type="success" show-icon />
    <br />
    <el-alert v-if="showWarning" :title="warnMessege" type="warning" effect="dark" show-icon />
    <br />
    <el-button v-if="showSucess" type="success" @click="sendLine()" round>點此連結Line Notify</el-button>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      Student: {
        choose: "webmail.fju.edu.tw",
      },
      loading: false,
      labelPosition: "left",
      showSucess: false,
      showWarning: false,
      warnMessege: "驗證失敗 請確認輸入",
      //labelPosition: "top", // form small device
    };
  },
  methods: {
    clickSubmit() {
      const config = {
        baseURL: "https://fjuapi.herokuapp.com//api.php?ldapcheck=true",
        //baseURL: "https://score-notify.herokuapp.com",
        url: "/",
        method: "post",
        //headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        headers: { "Content-Type": "application/json" },
        data: this.Student,
      };
      this.loading = true;
      this.$http.request(config).then((res) => {
        console.log(res.data.login);
        if (res.data.login) {
          this.showSucess = true;
        } else {
          this.showWarning = true;
        }
        this.loading = false;
      });
      console.log("Pressed");
    },
    sendLine() {
      //const callBackUri = "http://localhost:8888/score-notify/callback.php";
      const callBackUri = "https://score-notify.nde.tw/callback.php";
      let url =
        "https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=3TFljzgRHb8UjeOgDUV97C&redirect_uri=";
      url += callBackUri;
      url += "&scope=notify&state=";
      url += this.Student.mail_id;
      location.href = url;
    },
  },
  name: "Home",
};
</script>
